import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faCode, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import './App.css';
import './space.css';

function App() {
  const projects = [
    {
      title: "GPA Calculator",
      description: "A full-featured GPA calculator with database integration and user authentication.",
      image: "/components/proj 1.png",
      github: "https://github.com/fxrchis/gpa-calculator",
      demo: "https://fxrchis.github.io/gpa-calculator/",
      technologies: ["HTML", "CSS", "JavaScript"]
    },
    {
      title: "InternTracker",
      description: "Platform tracking internships and scholarships across the US.",
      image: "/components/InternTracker.png",
      demo: "https://interntracker.org",
      technologies: ["ReactJS", "Tailwind CSS", "Firebase"]
    }
  ];

  const skills = [
    {
      name: "HTML",
      icon: "/components/html.svg",
      color: "hover:text-orange-500"
    },
    {
      name: "CSS",
      icon: "/components/css.svg",
      color: "hover:text-blue-500"
    },
    {
      name: "Tailwind",
      icon: "/components/tailwind.svg",
      color: "hover:text-cyan-400"
    },
    {
      name: "React",
      icon: "/components/react.svg",
      color: "hover:text-blue-400"
    },
    {
      name: "JavaScript",
      icon: "/components/js.svg",
      color: "hover:text-yellow-400"
    },
    {
      name: "Python",
      icon: "/components/python.svg",
      color: "hover:text-blue-500"
    },
    {
      name: "Java",
      icon: "/components/java.svg",
      color: "hover:text-red-500"
    },
    {
      name: "Firebase",
      icon: "/components/firebase.svg",
      color: "hover:text-yellow-500"
    },
    {
      name: "MongoDB",
      icon: "/components/mongo.svg",
      color: "hover:text-green-500"
    }
  ];

  const [showEmail, setShowEmail] = React.useState(false);

  const handleEmailClick = () => {
    setShowEmail(true);
    // Copy email to clipboard
    navigator.clipboard.writeText('fernandobusiness9999@gmail.com');
  };

  return (
    <div className="min-h-screen bg-black text-white overflow-hidden">
      {/* Galaxy Background */}
      <div className="galaxy-background">
        <div className="galaxy-core" />
        <div className="galaxy-arm" />
        <div className="galaxy-dust" />
        <div className="galaxy-stars" />
        <div className="spiral-arm" />
        <div className="spiral-arm" />
        <div className="spiral-arm" />
      </div>

      {/* Cosmic Background Elements */}
      <div className="stars">
        <div className="star-group" />
        <div className="star-group" />
        <div className="star-group" />
      </div>
      <div className="nebula" />
      <div className="aurora">
        <div className="aurora__item" />
        <div className="aurora__item" />
      </div>
      
      {/* Shooting Stars */}
      {[...Array(5)].map((_, i) => (
        <div
          key={i}
          className="shooting-star"
          style={{
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
            animationDelay: `${i * 4}s`
          }}
        />
      ))}

      {/* Hero Section */}
      <section className="min-h-screen relative flex items-center justify-center cosmic-section">
        <div className="galaxy" style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
        <div className="container mx-auto px-4 z-10">
          <div className="text-center relative">
            <motion.h1 
              className="text-6xl font-bold mb-6"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <span className="highlight-text">Fernando Soto</span>
            </motion.h1>
            <motion.p 
              className="text-xl mb-8 text-blue-300"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              Exploring the Universe Through Code
            </motion.p>
            
            {/* Orbital System with Glowing Orbs */}
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -z-10">
              <div className="planet-orbit">
                <div className="planet" style={{ left: '50%', top: '0' }} />
                <div className="orb orb-blue" style={{ width: '30px', height: '30px', left: '20%', top: '20%' }} />
              </div>
              <div className="planet-orbit" style={{ width: '400px', height: '400px', animationDuration: '25s' }}>
                <div className="planet" style={{ right: '50%', bottom: '0' }} />
                <div className="orb orb-purple" style={{ width: '20px', height: '20px', right: '30%', bottom: '30%' }} />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* About Section */}
      <section className="py-20 relative cosmic-section">
        <div className="galaxy" style={{ top: '30%', right: '-20%', transform: 'rotate(45deg)' }} />
        <div className="orb orb-blue" style={{ width: '100px', height: '100px', top: '20%', left: '10%' }} />
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center mb-16">
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
            >
              <div className="description-card">
                <h2 className="text-3xl font-bold mb-6 highlight-text">About Me</h2>
                <p className="text-lg mb-6">
                  I'm a 16-year-old high school student with a deep passion for mathematics, programming, and the mysteries of the cosmos. My journey in technology and science has been driven by curiosity and the desire to understand the universe through code.
                </p>
              </div>
            </motion.div>
            
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="relative"
            >
              <img
                src="/components/photo2.jpg"
                alt="Fernando Soto"
                className="rounded-full w-64 h-64 mx-auto object-cover border-4 border-blue-500/30"
              />
              <div className="absolute inset-0 rounded-full bg-gradient-to-r from-blue-500/20 to-purple-500/20 animate-pulse" />
            </motion.div>
          </div>

          {/* Journey Sections */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="description-card"
            >
              <h3 className="text-2xl font-bold mb-4 highlight-text">Coding Journey</h3>
              <p className="text-gray-300 mb-4">
                I began coding in freshman year with AP Computer Science Principles, initially struggling with JavaScript. Joined a coding club and competed in Lockheed Martin's CodeQuest in April 2023. Over the summer, I mastered Python through Leetcode and Lockheed Martin's CodeQuest Academy, excelling in Python projects. In my Sophomore year, I would do AP CSA and learn Java, and eventually start to do web development.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="description-card"
            >
              <h3 className="text-2xl font-bold mb-4 highlight-text">Web Development</h3>
              <p className="text-gray-300 mb-4">
                I started learning HTML and CSS in early 2024 in my Sophmore year, as I had won 4th place in my FBLA club for States, meaning I was going to Nationals. I made a medium-scale project with HTML, CSS, and Javascript which was a functional GPA calculator that had a database and validated user input. Learning Web Development has been very fun. I've started to create medium scale projects, and I'm also designing a website for a non-profit student organization.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="description-card"
            >
              <h3 className="text-2xl font-bold mb-4 highlight-text">Interests</h3>
              <p className="text-gray-300 mb-4">
                I've always been interested in math, and now I'm learning calculus and physics. Recently as of my Freshman year, coding and computer science has been a new interest of mine. Astronomy is also one of my interests, as I want to become an astrophysicist and incorporate coding with my career in the future. Overall, computer science has been a heavy influence on my interests now as coding has made my skills better and learn new languages.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="description-card"
            >
              <h3 className="text-2xl font-bold mb-4 highlight-text">Activities</h3>
              <p className="text-gray-300 mb-4">
                I have attended a competitive programming event called CodeQuest by Lockheed Martin. For my practices, I've done CodeQuest Academy, Codewars, and Leetcode where I've solved more than 200 problems combined. I've made many small scale projects such as a planet simulator, a word counter, a program which shows current NBA game stats, and more. Some of my projects are in my github, but majority are kept in my folders.
              </p>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Skills Section */}
      <section className="py-20 relative cosmic-section">
        <div className="galaxy" style={{ bottom: '0%', left: '-20%', transform: 'rotate(-45deg)' }} />
        <div className="orb orb-purple" style={{ width: '80px', height: '80px', bottom: '20%', right: '10%' }} />
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold mb-12 text-center highlight-text">Skills & Technologies</h2>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8">
            {skills.map((skill, index) => (
              <motion.div
                key={skill.name}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="flex flex-col items-center"
              >
                <div className={`description-card p-6 aspect-square flex items-center justify-center transition-all duration-300 ${skill.color}`}>
                  <img
                    src={skill.icon}
                    alt={skill.name}
                    className="w-16 h-16"
                  />
                </div>
                <p className="mt-4 text-center font-medium">{skill.name}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Projects Section */}
      <section className="py-20 relative cosmic-section">
        <div className="galaxy" style={{ top: '50%', right: '-30%', transform: 'rotate(180deg)' }} />
        <div className="orb orb-blue" style={{ width: '120px', height: '120px', top: '40%', left: '5%' }} />
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold mb-12 text-center highlight-text">Projects</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {projects.map((project, index) => (
              <motion.div
                key={index}
                className="bg-opacity-10 bg-white rounded-xl overflow-hidden transition-all duration-300 border border-opacity-20 border-white hover:border-opacity-40 hover:shadow-[0_0_15px_rgba(59,130,246,0.2)]"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
                whileHover={{ 
                  y: -5,
                  transition: { duration: 0.2 }
                }}
              >
                <div className="relative w-full aspect-video">
                  <img
                    src={project.image}
                    alt={project.title}
                    className="w-full h-full object-cover"
                    loading="lazy"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                </div>
                <div className="p-6">
                  <h3 className="text-2xl font-bold mb-3 text-blue-300">{project.title}</h3>
                  <p className="text-gray-300 mb-4">{project.description}</p>
                  <div className="flex flex-wrap gap-2 mb-4">
                    {project.technologies.map((tech, techIndex) => (
                      <span
                        key={techIndex}
                        className="px-3 py-1 text-sm rounded-full bg-opacity-20 bg-blue-500 text-blue-300"
                      >
                        {tech}
                      </span>
                    ))}
                  </div>
                  <div className="flex gap-4">
                    {project.github && (
                      <a
                        href={project.github}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center gap-2 text-blue-300 hover:text-blue-400 transition-colors"
                      >
                        <FontAwesomeIcon icon={faGithub} />
                        <span>GitHub</span>
                      </a>
                    )}
                    {project.demo && (
                      <a
                        href={project.demo}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center gap-2 text-blue-300 hover:text-blue-400 transition-colors"
                      >
                        <FontAwesomeIcon icon={faExternalLinkAlt} />
                        <span>Live Demo</span>
                      </a>
                    )}
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="py-20 relative cosmic-section">
        <div className="galaxy" style={{ bottom: '-20%', left: '-20%' }} />
        <div className="orb orb-purple" style={{ width: '90px', height: '90px', bottom: '30%', right: '15%' }} />
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-4xl font-bold mb-8 highlight-text">Let's Connect</h2>
          <p className="text-xl mb-8 text-blue-300">
            Interested in collaborating on projects or discussing the mysteries of the universe?
          </p>
          <div className="flex justify-center gap-8 mb-8">
            <motion.a
              href="https://github.com/fxrchis"
              target="_blank"
              rel="noopener noreferrer"
              className="text-3xl text-gray-300 hover:text-blue-400 transition-colors"
              whileHover={{ scale: 1.1 }}
            >
              <FontAwesomeIcon icon={faGithub} />
            </motion.a>
            <motion.a
              href="https://www.linkedin.com/in/fernando-soto-briceno-440346313/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-3xl text-gray-300 hover:text-blue-400 transition-colors"
              whileHover={{ scale: 1.1 }}
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </motion.a>
            <motion.div
              className="text-3xl text-gray-300 hover:text-blue-400 transition-colors relative cursor-pointer group"
              whileHover={{ scale: 1.1 }}
            >
              <FontAwesomeIcon icon={faEnvelope} />
              <motion.div
                className="absolute left-1/2 transform -translate-x-1/2 -top-12 bg-gray-800 px-4 py-2 rounded-lg text-sm whitespace-nowrap pointer-events-none opacity-0 group-hover:opacity-100 transition-opacity duration-200"
              >
                fernandobusiness9999@gmail.com
                <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 rotate-45 w-2 h-2 bg-gray-800"></div>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
